
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

* {
    margin: 0px;
    padding:0px;
}
body.pushable{
    background-color: #ccc!important;
}
body{
    background-color:#ecf0f1 !important;
   
}
#menu-direita{
    overflow-y: auto!important;
    min-width: 230px!important;
}
.background-menu-representantes {
    background:linear-gradient(#004d4d,#34495e) !important;
}
.background-menu-b2b {
    background: #640000 !important;
}
#menu-topo{
    background:#fff !important;
    color: #004d35 !important;
    box-shadow: none;
    height: 60px!important;
    position: fixed !important;
}
#menu-topo > div > * {
    background:#fff !important;
    color: #004d35 !important;
}
#menu {
    width: 100%!important;
    box-sizing: 0px;
}
#nav{
    width: 100%!important;
}

.item{
    background-color: #000;
    color:#fff!important;
}
.chave_catalogo{
    margin-left:70%;
}
/*classe que coloca a cor preta para fonte*/
.link{
    color:#000!important;
}
.link:hover{
    color:#000!important;
}
/*todos icone*/
.icon{
    color: #000!important;

}
h1 {
    font-family: 'Ubuntu', sans-serif;
}
.icon:hover{
    color: #000!important;

}
/*icone das opções do menu*/
.icon2{
    color: #fff!important;

}
.icon2:hover{
    color: #fff!important;

}
#conteiner{
    padding:5% 18% 5% 2%;
}

#conteeiner{
    padding-top: 15vh;   
}

#app {
    overflow: auto !important;
    max-height: 100vh;
}
.basic segment{
    background-color: green;
    margin: 0;
}
/*botao de sim e nao da modal de permissão*/
 .button_modal{
      margin-left:80%!important;
  }

a.item:focus {
    box-shadow: inset 0 0 10px #045;
    /* color: #045 !important; */
    color: #fff;
    background: rgba(0,0,0,0.2);
}